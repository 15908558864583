import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SearchersStatsRowInterface } from 'src/types';

export const useSearchersStats = (): SearchersStatsRowInterface[] => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [searchParams] = useSearchParams();
  const chainId = searchParams.get('network');

  const { data, refetch } = useQuery(
    ['searchersStats'],
    async () => {
      const { data } = await axios({
        url: `${apiUrl}/stats/searchers`,
        params: {
          chainId: Number(chainId) || undefined,
        },
      });
      return data;
    },
    { enabled: false }
  );

  useEffect(() => {
    refetch();
    const timer = setInterval(refetch, 5000);
    return () => {
      clearInterval(timer);
    };
  }, [chainId, refetch]);

  return data;
};
