import './BundleCard.scss';

import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { ReactNode, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AddressLink, StatusReason, TxLink } from 'src/components';
import { useBundle } from 'src/hooks';
import {
  BundleFailReason,
  BundleStatus as BundleStatusEnum,
  Network,
  SearchersBid,
} from 'src/types';

export const BundleCard = ({
  id,
  network,
}: {
  id: string;
  network?: Network;
}): JSX.Element => {
  const { status, data, isLoading, isFetching } = useBundle(id);
  const [searchParams] = useSearchParams();
  const godMode = searchParams.get('godMode');
  if (isLoading || isFetching || !data.broadcasterInfo) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', margin: '20px auto' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (isLoading || isFetching) {
    return (
      <Grid container>
        <Grid item xs={12} padding={2}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        </Grid>
      </Grid>
    );
  }

  const leftInfoColumn = [
    {
      title: 'Fee receiver',
      description: (
        <AddressLink
          address={data.broadcasterInfo.broadcasterFeeReceiver}
          chainId={data.chainId}
          disableShortView
        />
      ),
    },
    {
      title: 'Requested auction time',
      description: data.broadcasterInfo.waitSearchersMs + ' ms',
    },
    {
      title: 'Creation time',
      description: moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss.SSS'),
    },
    {
      title: 'Submit time',
      description: data.submittedAt
        ? moment(data.submittedAt).format('YYYY-MM-DD HH:mm:ss.SSS')
        : 'none',
    },
    {
      title: 'Mempool discovery time',
      description: data.mempoolAt
        ? moment(data.mempoolAt).format('YYYY-MM-DD HH:mm:ss.SSS')
        : 'none',
    },
  ];

  const rightInfoColumn = [
    {
      title: 'Case ID',
      description: <AddressLink address={id} disableShortView />,
    },
    {
      title: 'Allow front run',
      description: data.broadcasterInfo.allowFrontrun ? 'true' : 'false',
    },
    {
      title: 'Min searcher bid',
      description:
        formatUnits(
          data.broadcasterInfo.minSearcherBid
            ? data.broadcasterInfo.minSearcherBid
            : '0',
          'ether'
        ) +
        ' ' +
        network?.nativeCurrencySymbol,
    },
  ];

  const leftBottomInfoColumn = [
    {
      title:
        ['0', 'NaN'].includes(data.broadcasterPaymentUsd) && data.submittedAt
          ? 'Empty status reason'
          : undefined,
      description: (
        <StatusReason
          statusReason={data.statusReason}
          className={'status-reason-txt'}
        />
      ),
    },
  ];

  if (data.broadcasterInfo.minBundleGasPrice) {
    rightInfoColumn.push({
      title: 'Min gas price',
      description:
        formatUnits(data.broadcasterInfo.minBundleGasPrice, 'gwei') + ' Gwei',
    });
  }

  if (data.broadcasterInfo.recommendedBundleGasPrice) {
    rightInfoColumn.push({
      title: 'Recommended gas price',
      description:
        formatUnits(data.broadcasterInfo.recommendedBundleGasPrice, 'gwei') +
        ' Gwei',
    });
  }

  if (data.broadcasterInfo.requiredTxConfig) {
    const config = data.broadcasterInfo.requiredTxConfig;
    rightInfoColumn.push({
      title: 'Required tx type',
      description: BigNumber.from(config.type).toString(),
    });
    if (config.gas_price) {
      rightInfoColumn.push({
        title: 'Required gasPrice',
        description: formatUnits(config.gas_price, 'gwei') + ' Gwei',
      });
    }
    if (config.max_fee_per_gas) {
      rightInfoColumn.push({
        title: 'Required maxFeePerGas',
        description: formatUnits(config.max_fee_per_gas, 'gwei') + ' Gwei',
      });
    }
    if (config.max_priority_fee_per_gas) {
      rightInfoColumn.push({
        title: 'Required maxPriorityFeePerGas',
        description:
          formatUnits(config.max_priority_fee_per_gas, 'gwei') + ' Gwei',
      });
    }
  }

  if (typeof data.unique === 'boolean') {
    leftInfoColumn.push({
      title: 'Unique',
      description: data.unique.toString(),
    });
  }

  if (data.unique === false && data.nonUniquenessReason) {
    leftInfoColumn.push({
      title: 'Found in',
      description: data.nonUniquenessReason.join(', '),
    });
  }

  if (data.revshareInfo) {
    rightInfoColumn.push({
      title: 'Revshare provider address',
      description: data.revshareInfo.revshareEOA,
    });
  }

  return (
    <Box component={'div'} className={'bundle-card'} marginTop={3}>
      <Grid container spacing={3} paddingBottom={3}>
        <Grid item xs={6}>
          {leftInfoColumn.map((item, index) => (
            <InfoItem key={index} {...item} />
          ))}
        </Grid>
        <Grid item xs={6}>
          {rightInfoColumn.map((item, index) => (
            <InfoItem key={index} {...item} />
          ))}
        </Grid>

        <Grid item xs={6}>
          {leftBottomInfoColumn.map((item, index) => (
            <InfoItem key={index} {...item} />
          ))}
        </Grid>
      </Grid>
      {data.revshareInfo && !!godMode && (
        <Grid container paddingBottom={2}>
          <Grid item xs={12}>
            <Box component={'div'} sx={{ marginBottom: '6px' }}>
              <Typography variant="h4">Revshare Config</Typography>
            </Box>
          </Grid>
          <Paper
            elevation={1}
            style={{
              padding: '6px 16px',
            }}>
            <Stack
              direction={'column'}
              justifyContent="center"
              alignItems="center"
              spacing={1}>
              {data.revshareInfo.revshareConfig.map((item, index) => (
                <Stack
                  key={index}
                  direction={'row'}
                  justifyContent="space-between"
                  alignItems="center"
                  width={'100%'}
                  spacing={2}>
                  <Typography variant="h4" sx={{ lineHeight: 'normal' }}>
                    {item.address}
                  </Typography>
                  <Typography variant="subtitle1">
                    {item.feePercent}%
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Paper>
        </Grid>
      )}
      <Grid container paddingBottom={2}>
        <Grid item xs={12}>
          <Box component={'div'} sx={{ marginBottom: '6px' }}>
            <Typography variant="h4">Searcher Bids</Typography>
          </Box>
          <Paper elevation={1}>
            <TableContainer>
              <Table size="small" aria-label="table c">
                <TableHead>
                  <TableRow>
                    <TableCell width={'225px'}>Address</TableCell>
                    <TableCell width={'245px'}>Bundle Tx Hashes</TableCell>
                    <TableCell align="right">Average Gas Price, gwei</TableCell>
                    <TableCell align="right">Searcher Bid</TableCell>
                    <TableCell align="right">Broadcaster Payment</TableCell>
                    <TableCell align="right">Response time, ms</TableCell>
                    <TableCell align="right">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {status === 'success' &&
                    data.searchersBidList.map(
                      (row: SearchersBid, index: number) => (
                        <TableRow key={index}>
                          <TableCell sx={{ verticalAlign: 'top' }}>
                            {row.address && (
                              <AddressLink
                                address={row.address}
                                chainId={data.chainId}
                                length={8}
                              />
                            )}
                          </TableCell>
                          <TableCell sx={{ verticalAlign: 'top' }}>
                            {!!row.transactionHashes &&
                              row.transactionHashes.map(
                                (hash: string, index: number) => (
                                  <Box key={index}>
                                    <TxLink
                                      hash={hash}
                                      chainId={data.chainId}
                                      length={10}
                                    />
                                  </Box>
                                )
                              )}
                          </TableCell>
                          <TableCell
                            sx={{ verticalAlign: 'top' }}
                            align="right">
                            {row.avgBundleGasPriceGwei || '0.0'}
                          </TableCell>
                          <TableCell
                            sx={{ verticalAlign: 'top' }}
                            align="right">
                            ${Number(row.searcherBidUsd || '0').toFixed(3)}
                          </TableCell>
                          <TableCell
                            sx={{ verticalAlign: 'top' }}
                            align="right">
                            {row.isWinner
                              ? '$' +
                                Number(
                                  row.broadcasterPaymentUSD || '0'
                                ).toFixed(3)
                              : ''}
                          </TableCell>
                          <TableCell
                            sx={{ verticalAlign: 'top' }}
                            align="right">
                            {+row.responseTime}
                          </TableCell>
                          <TableCell
                            sx={{ verticalAlign: 'top' }}
                            align="right">
                            <BundleStatus bundleInfo={row} />
                          </TableCell>
                        </TableRow>
                      )
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const InfoItem = ({
  title,
  description,
}: {
  title: string | undefined;
  description: ReactNode | undefined;
}): JSX.Element => {
  if (title) {
    return (
      <Grid container>
        <Grid item xs={12} lg={4.5} paddingBottom={1}>
          <Typography variant="h4" sx={{ lineHeight: 'normal' }}>
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          lg={7.5}
          paddingBottom={{ xs: 2, lg: 1 }}
          sx={{ textAlign: 'left' }}>
          <Typography variant="subtitle1">{description}</Typography>
        </Grid>
      </Grid>
    );
  } else {
    return <></>;
  }
};

const BundleStatus = ({
  bundleInfo,
}: {
  bundleInfo: SearchersBid;
}): JSX.Element | null => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (): void => {
    setAnchorEl(null);
  };

  const infoOpen = Boolean(anchorEl);
  if (
    (bundleInfo.status === BundleStatusEnum.new && bundleInfo.isWinner) ||
    bundleInfo.status === BundleStatusEnum.winner
  ) {
    return (
      <Chip
        className="bundle-status-tag"
        sx={{ backgroundColor: '#d3b071' }}
        size="small"
        color="info"
        label="Winner"
        icon={<EmojiEventsIcon />}
      />
    );
  } else if (bundleInfo.status === BundleStatusEnum.lateness) {
    return (
      <Chip
        className="bundle-status-tag"
        sx={{ backgroundColor: '#b5b5b5' }}
        size="small"
        color="info"
        label="Lateness"
      />
    );
  } else if (bundleInfo.status === BundleStatusEnum.overBidded) {
    return (
      <Chip
        className="bundle-status-tag"
        sx={{ backgroundColor: '#b5b5b5' }}
        size="small"
        color="info"
        label="OverBidded"
      />
    );
  } else if (bundleInfo.status === BundleStatusEnum.validationFail) {
    return (
      <>
        <Chip
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          className="bundle-status-tag"
          sx={{ backgroundColor: '#c66262' }}
          size="small"
          color="info"
          label="Validation Fail"
        />
        <Popover
          sx={{
            pointerEvents: 'none',
          }}
          open={infoOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus>
          <FailReason bundleInfo={bundleInfo} />
        </Popover>
      </>
    );
  } else {
    return null;
  }
};

const FailReason = ({
  bundleInfo,
}: {
  bundleInfo: SearchersBid;
}): JSX.Element | null => {
  if (bundleInfo.failReason === BundleFailReason.incorrectNonce) {
    return (
      <Typography fontSize={'small'} className={'popover-fail'}>
        Incorrect Nonce
      </Typography>
    );
  } else if (bundleInfo.failReason === BundleFailReason.incorrectGas) {
    return (
      <Typography fontSize={'small'} className={'popover-fail'}>
        Incorrect Gas
      </Typography>
    );
  } else if (bundleInfo.failReason === BundleFailReason.lowBalance) {
    return (
      <Typography fontSize={'small'} className={'popover-fail'}>
        Not Enough Balance
      </Typography>
    );
  } else if (bundleInfo.failReason === BundleFailReason.incorrectChainId) {
    return (
      <Typography fontSize={'small'} className={'popover-fail'}>
        Incorrect ChainId
      </Typography>
    );
  } else if (
    bundleInfo.failReason === BundleFailReason.incompatibleConfiguration
  ) {
    return (
      <Typography fontSize={'small'} className={'popover-fail'}>
        Incompatible Bundle Configuration
      </Typography>
    );
  } else if (bundleInfo.failReason === BundleFailReason.frontrunNotAllowed) {
    return (
      <Typography fontSize={'small'} className={'popover-fail'}>
        Frontrun Transactions Not Allowed
      </Typography>
    );
  } else if (bundleInfo.failReason === BundleFailReason.unknown) {
    return (
      <Typography fontSize={'small'} className={'popover-fail'}>
        Unknown
      </Typography>
    );
  } else if (bundleInfo.failReason === BundleFailReason.simulationFailed) {
    return (
      <Typography fontSize={'small'} className={'popover-fail'}>
        Simulation Failed
      </Typography>
    );
  } else {
    return null;
  }
};
