export enum DisplayBroadcasterResponseStatus {
  success = 'success',
  ingoredDueToEthTransferTx = 'ingoredDueToEthTransferTx',
  ingoredDueToApprovalTx = 'ingoredDueToApprovalTx',
  ingoredDueToTokenTransferTx = 'ingoredDueToTokenTransferTx',

  ignoredDueToBridgeInteractionTx = 'ignoredDueToBridgeInteractionTx',
  ignoredDueToENSTx = 'ignoredDueToENSTx',
  ignoredDueToNftTx = 'ignoredDueToNftTx',
  ignoredDueToWethTx = 'ignoredDueToWethTx',
  ignoredDueToContractCreation = 'ignoredDueToContractCreation',
}
