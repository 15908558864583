import { Box, Paper } from '@mui/material';
import { SearchersStatsTable } from 'src/components';

export const SearcherStats = (): JSX.Element => {
  return (
    <Box>
      <Paper elevation={2} sx={{ p: 2 }}>
        <SearchersStatsTable />
      </Paper>
    </Box>
  );
};
