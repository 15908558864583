import { Avatar, Stack, TableCell, TableRow } from '@mui/material';
import Identicon from '@polkadot/react-identicon';
import { networks } from 'src/config';
import { SearchersStatsRowInterface } from 'src/types';

import { AddressLink } from '../AddressLink/AddressLink';

export const SearchersStatsRow = ({
  row,
}: {
  row: SearchersStatsRowInterface;
}): JSX.Element => {
  const network = networks.find((n) => n.chainId === Number(row.chainId));
  return (
    <TableRow key={'requests'}>
      <TableCell align="left">
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          justifyContent="flex-start"
          alignItems="center"
          spacing={{ md: 1, xs: 2 }}>
          <Avatar alt={row.primaryAddress} sx={{ textAlign: 'center' }}>
            <Identicon
              value={row.primaryAddress}
              size={38}
              theme={'ethereum'}
            />
          </Avatar>
          <AddressLink address={row.primaryAddress} length={12} />
        </Stack>
      </TableCell>
      <TableCell align="center">
        {network ? network.name : row.chainId}
      </TableCell>
      <TableCell
        align="center"
        className={row.connected ? 'connected' : 'disconnected'}>
        {row.connected ? 'Connected' : 'Disconnected'}
      </TableCell>
    </TableRow>
  );
};
