import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ProfitSplit } from 'src/types';

export const useMevsProfit = (): {
  profitData: ProfitSplit[];
  isFetching: boolean;
} => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [searchParams] = useSearchParams();
  const period = searchParams.get('period');
  const chainId = searchParams.get('network');
  const broadcasterId = searchParams.get('broadcasterId');
  let periodTime = 1000 * 60 * 60 * 24;
  if (period === 'week') {
    periodTime *= 7;
  }
  if (period === 'month') {
    periodTime *= 30;
  }
  const { data, refetch, isFetching } = useQuery(
    ['mev_profit'],
    async () => {
      const { data } = await axios({
        url: `${apiUrl}/mev/profit`,
        params: {
          period: new Date(Date.now() - periodTime),
          chainId: Number(chainId) || undefined,
          broadcasterId: broadcasterId || undefined,
        },
      });
      return data;
    },
    { enabled: false }
  );

  useEffect(() => {
    refetch();
  }, [period, chainId, broadcasterId, refetch]);

  return { profitData: data, isFetching };
};
