import { Network } from '../types';

export const networks: Network[] = [
  {
    chainId: 0,
    name: 'All',
    nativeCurrencySymbol: null,
    color: null,
    icon: '/all_chains_logo.png',
  },
  {
    chainId: 1,
    name: 'ETH Mainnet',
    explorer: 'https://etherscan.io',
    nativeCurrencySymbol: 'ETH',
    color: '#517be7',
    icon: '/ethereum_logo.png',
  },
  {
    chainId: 1313161554,
    name: 'Aurora',
    explorer: 'https://aurorascan.dev',
    nativeCurrencySymbol: 'ETH',
    color: '#6acc48',
    icon: '/aurora_logo.png',
  },
  {
    chainId: 137,
    name: 'Polygon',
    explorer: 'https://polygonscan.com',
    nativeCurrencySymbol: 'MATIC',
    color: '#7f45df',
    icon: '/polygon_logo.png',
  },
  {
    chainId: 43114,
    name: 'Avalanche',
    explorer: 'https://snowtrace.io',
    nativeCurrencySymbol: 'AVAX',
    color: '#e04040',
    icon: '/avalanche_logo.png',
  },
  {
    chainId: 56,
    name: 'BSC',
    explorer: 'https://bscscan.com/',
    nativeCurrencySymbol: 'BNB',
    color: '#ecb42e',
    icon: '/bsc_logo.png',
  },
  {
    chainId: 8453,
    name: 'Base',
    explorer: 'https://basescan.org/',
    nativeCurrencySymbol: 'ETH',
    color: '#1F51FF',
    icon: '/base_logo.png',
  },
];
