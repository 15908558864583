import './TxLink.scss';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Link, Tooltip } from '@mui/material';
import { CopyToClipboard } from 'src/components';
import { networks } from 'src/config';

export const TxLink = ({
  hash,
  chainId,
  length = 4,
}: {
  hash: string;
  chainId: number;
  txBlockNumber?: number;
  txIndex?: number;
  length?: number;
}): JSX.Element => {
  const network = networks.find((n) => n.chainId === chainId);
  const formatedHash =
    hash.slice(0, length + 2) + '...' + hash.slice(length * -1);

  return (
    <Tooltip title={hash}>
      <Box className="tx-link" component={'span'} sx={{ whiteSpace: 'nowrap' }}>
        {network?.explorer ? (
          <Link href={`${network.explorer}/tx/${hash}`} target="_blank">
            {formatedHash}
          </Link>
        ) : (
          <>{formatedHash}</>
        )}
        <CopyToClipboard text={hash}>
          <ContentCopyIcon className="copy-icon" />
        </CopyToClipboard>
      </Box>
    </Tooltip>
  );
};
