export enum BroadcasterResponseStatus {
  success = 'success',
  blacklistedBroadcaster = 'blacklistedBroadcaster',
  foundInMempool = 'foundInMempool',
  duplicateTransaction = 'duplicateTransaction',
  maxFeePerGasIsTooLow = 'maxFeePerGasIsTooLow',
  ingoredDueToEthTransferTx = 'ingoredDueToEthTransferTx',
  ingoredDueToApprovalTx = 'ingoredDueToApprovalTx',
  ingoredDueToTokenTransferTx = 'ingoredDueToTokenTransferTx',
  ignoredDueToFailedSimulation = 'ignoredDueToFailedSimulation',

  ignoredDueToBridgeInteractionTx = 'ignoredDueToBridgeInteractionTx',
  ignoredDueToENSTx = 'ignoredDueToENSTx',
  ignoredDueToNftTx = 'ignoredDueToNftTx',
  ignoredDueToWethTx = 'ignoredDueToWethTx',
  ignoredDueToContractCreation = 'ignoredDueToContractCreation',

  invalidGasLimit = 'invalidGasLimit',
}
