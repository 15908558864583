import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { NoMevTxsStats } from 'src/types';

export const useNoMevTxsGasDifference = (): {
  data: NoMevTxsStats;
  isLoading: boolean;
} => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [searchParams] = useSearchParams();
  const period = (searchParams.get('period') as 'week' | 'month') || 'day';
  const chainId = searchParams.get('network');
  const broadcasterId = searchParams.get('broadcasterId');
  let periodTime = 1000 * 60 * 60 * 24;
  if (period === 'week') {
    periodTime *= 7;
  }
  if (period === 'month') {
    periodTime *= 30;
  }
  const { data, isLoading, refetch } = useQuery(
    ['no_mev_txs'],
    async () => {
      const { data } = await axios({
        url: `${apiUrl}/noMevTxs`,
        params: {
          interval: new Date(Date.now() - periodTime),
          chainId: Number(chainId) || undefined,
          broadcasterId: broadcasterId || undefined,
        },
      });
      return data;
    },
    { enabled: false }
  );
  useEffect(() => {
    refetch();
  }, [period, chainId, broadcasterId, refetch]);

  return { data, isLoading };
};
