export enum BundleFailReason {
  unknown = 'unknown',
  incorrectNonce = 'incorrectNonce',
  lowBalance = 'lowBalance',
  incorrectChainId = 'incorrectChainId',
  incorrectGas = 'incorrectGas',
  incompatibleConfiguration = 'incompatibleConfiguration',
  frontrunNotAllowed = 'frontrunNotAllowed',
  simulationFailed = 'simulationFailed',
}
