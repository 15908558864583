export enum ChainIdEnum {
  eth = 1,
  goerli = 5,
  bsc = 56,
  'bsc-tst' = 97,
  aurora = 1313161554,
  'aurora-tst' = 1313161555,
  pol = 137,
  ava = 43114,
  base = 8453,
}
