import './BroadcasterRequestsCount.scss';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { usePrometheus } from 'src/hooks';
import { BroadcasterResponseStatus } from 'src/types';

export const BroadcasterRequestsCount = (): JSX.Element => {
  const dataDay = usePrometheus('day');
  const dataWeek = usePrometheus('week');
  const dataMonth = usePrometheus('month');

  return (
    <>
      <TableContainer>
        <Table
          className="broadcaster-requests"
          aria-label="simple table"
          padding="none">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h2" gutterBottom>
                  Broadcasters
                </Typography>
              </TableCell>
              <TableCell align="center">24h</TableCell>
              <TableCell align="center">7d</TableCell>
              <TableCell align="center">30d</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={'requests'}>
              <TableCell component="th" scope="row">
                Transactions
              </TableCell>
              <TableCell align="center">
                {dataDay.data?.requests[BroadcasterResponseStatus.success]
                  .total || 0}
              </TableCell>
              <TableCell align="center">
                {dataWeek.data?.requests[BroadcasterResponseStatus.success]
                  .total || 0}
              </TableCell>
              <TableCell align="center">
                {dataMonth.data?.requests[BroadcasterResponseStatus.success]
                  .total || 0}
              </TableCell>
            </TableRow>
            <TableRow key={'submits'}>
              <TableCell component="th" scope="row">
                Bundle Submits
              </TableCell>
              <TableCell align="center">
                {dataDay.data?.submits.total || 0}
              </TableCell>
              <TableCell align="center">
                {dataWeek.data?.submits.total || 0}
              </TableCell>
              <TableCell align="center">
                {dataMonth.data?.submits.total || 0}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
