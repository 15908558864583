import { Box, Link, TableCell, TableRow, Tooltip } from '@mui/material';
import moment from 'moment';
import { AddressLink, TimeAgo, TxLink } from 'src/components';
import { networks } from 'src/config';
import { ChainIdEnum } from 'src/types';
import { MevList } from 'src/types/MevList';

export const MevRow = ({
  row,
  mevType,
}: {
  row: MevList;
  mevType: string | null;
}): JSX.Element => {
  const network = networks.find((n) => n.chainId === row.chainId);

  return (
    <>
      <TableRow sx={[{ td: { border: 'none' } }]}>
        <TableCell>
          {row.broadcasterId ? (
            <AddressLink address={row.broadcasterId} chainId={row.chainId} />
          ) : (
            <AddressLink
              address="Unknown Broadcaster"
              disableShortView
              disableCopyButton
            />
          )}
        </TableCell>
        <TableCell sx={{ p: 0 }} align={'center'}>
          <Box className="kolibrio-unique-icon">
            {row.unique ? (
              <Tooltip title="Transaction was unique to Kolibrio OFA">
                <Box
                  component="img"
                  sx={{
                    maxHeight: '18px',
                  }}
                  alt="Unique"
                  src="/icon.png"
                />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  row.chainId === ChainIdEnum.eth
                    ? row.nonUniquenessReason
                      ? `Transaction found in: ${row.nonUniquenessReason.join(
                          ', '
                        )}`
                      : `Not unique`
                    : 'No information about uniqueness'
                }>
                <Box
                  className={'kolibrio-unique-icon-disabled'}
                  component="img"
                  sx={{
                    maxHeight: '18px',
                  }}
                  alt="Non-unique"
                  src="/icon-off.png"
                />
              </Tooltip>
            )}
          </Box>
        </TableCell>
        <TableCell sx={{ p: 0 }} align={'center'}>
          {row.isBid ? (
            <Link
              href={`/?search=${row.bevId}${
                row.broadcasterPaymentUsd ? '' : '&debug_mode=1'
              }`}
              target="_blank">
              <Box className="kolibrio-bid-icon">
                <Tooltip title="Bids have been placed">
                  <Box
                    component="img"
                    sx={{
                      maxHeight: '26px',
                    }}
                    alt="Bidded"
                    src="/auction-hammer.png"
                  />
                </Tooltip>
              </Box>
            </Link>
          ) : (
            <Box className="kolibrio-no-bid-icon">
              <Tooltip title="ALPHA! No searcher bided on this strategy in Kolibrio. Be the first one to keep the low bribe!">
                <Box
                  component="img"
                  sx={{
                    opacity: '0.5',
                    maxHeight: '26px',
                  }}
                  alt="Not-bidded"
                  src="/auction-hammer-grey.png"
                />
              </Tooltip>
            </Box>
          )}
        </TableCell>
        <TableCell sx={{ p: 0 }} align={'center'}>
          {(row.searcherBidUsd || 0).toFixed(2)}
        </TableCell>
        <TableCell sx={{ p: 0 }} align={'center'}>
          {(row.broadcasterPaymentUsd || 0).toFixed(2)}
        </TableCell>
        <TableCell align="center">
          {row.profit ? +row.profit.toFixed(2) : 0}
        </TableCell>
        <TableCell>
          <TxLink hash={row.broadcasterTxHash} chainId={row.chainId} />
        </TableCell>
        <TableCell>{row.broadcasterTxBlocknumber}</TableCell>
        <TableCell align="center">{row.broadcasterTxIndex}</TableCell>
        {row.frontrunTxHash ? (
          <>
            <TableCell align="center">
              <TxLink hash={row.frontrunTxHash} chainId={row.chainId} />
            </TableCell>
            <TableCell>{row.frontrunTxBlocknumber}</TableCell>
            <TableCell align="center">{row.frontrunTxIndex}</TableCell>
          </>
        ) : mevType && mevType !== 'arbitrage' ? (
          <TableCell align="center" colSpan={3}>
            None
          </TableCell>
        ) : null}
        {row.backrunTxHash ? (
          <>
            <TableCell align="center">
              <TxLink hash={row.backrunTxHash} chainId={row.chainId} />
            </TableCell>
            <TableCell>{row.backrunTxBlocknumber}</TableCell>
            <TableCell align="center">{row.backrunTxIndex}</TableCell>
          </>
        ) : (
          <TableCell align="center" colSpan={3}>
            None
          </TableCell>
        )}
        <TableCell align="center">
          {network ? (
            <img
              style={{ width: '20px', height: '20px' }}
              src={network.icon}></img>
          ) : (
            row.chainId
          )}
        </TableCell>
        <TableCell align="right">
          <TimeAgo
            date={row.createdAt}
            title={
              <span
                style={{
                  display: 'block',
                  textAlign: 'right',
                }}>
                Received:{' '}
                {moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss.SSS')}
                {row.mempoolDiscoveryDate && (
                  <>
                    <br />
                    Found in mempool:{' '}
                    {moment(row.mempoolDiscoveryDate).format(
                      'YYYY-MM-DD HH:mm:ss.SSS'
                    )}
                  </>
                )}
                {row.broadcasterTxMiningTimestamp && (
                  <>
                    <br />
                    Mined:{' '}
                    {moment(row.broadcasterTxMiningTimestamp).format(
                      'YYYY-MM-DD HH:mm:ss.SSS'
                    )}
                  </>
                )}
              </span>
            }
          />
        </TableCell>
      </TableRow>
    </>
  );
};
