import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4889ff',
    },
  },
  typography: {
    fontFamily: ['Roboto', '"Helvetica Neue"'].join(','),
    h1: {
      fontSize: 26,
      fontWeight: 500,
    },
    h2: {
      fontSize: 22,
      fontWeight: 500,
    },
    h3: {
      fontSize: 18,
      fontWeight: 500,
    },
    h4: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 'normal',
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 'normal',
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 300,
    },
  },
});

export const ThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};
