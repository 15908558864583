import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MevList, Pagination } from 'src/types';

export const useMevs = (
  page: number,
  rowsPerPage: number
): {
  data: Pagination<MevList[]>;
  chainId: string | null;
  broadcasterId: string | null;
  isFetching: boolean;
} => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [searchParams] = useSearchParams();
  const chainId = searchParams.get('network');
  const broadcasterId = searchParams.get('broadcasterId');
  const search = searchParams.get('search');
  const type = searchParams.get('type');
  const unique = searchParams.get('unique');
  const notSameBlockBackrun = searchParams.get('notSameBlockBackrun');
  const godMode = searchParams.get('godMode');

  const { data, refetch, isFetching } = useQuery(
    ['mev'],
    async () => {
      const { data } = await axios({
        url: `${apiUrl}/mev`,
        params: {
          take: rowsPerPage,
          skip: page * rowsPerPage,
          chainId: Number(chainId) || undefined,
          broadcasterId: broadcasterId || undefined,
          search: search || undefined,
          type: type || 'arbitrage',
          filter: {
            unique,
            notSameBlockBackrun,
          },
          godMode: godMode || undefined,
        },
      });
      return data;
    },
    { enabled: false }
  );

  useEffect(() => {
    refetch();
  }, [
    page,
    rowsPerPage,
    broadcasterId,
    chainId,
    search,
    type,
    unique,
    notSameBlockBackrun,
    refetch,
  ]);

  return { data, chainId, broadcasterId, isFetching };
};
