import './SearcherCount.scss';

import { Box, Typography } from '@mui/material';
import { useSearchersCount } from 'src/hooks';

export const SearcherCount = (): JSX.Element => {
  const data = useSearchersCount();

  return (
    <Box className="connected-searchers">
      <Typography variant="h2" gutterBottom>
        Connected searchers
      </Typography>
      <Box className="count" color="palette.primary.light">
        {data || 0}
      </Box>
    </Box>
  );
};
