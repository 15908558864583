import './MevList.scss';

import {
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Filters, MevSelector, ProfitTable, Search } from 'src/components';
import { useBevsProfit, useMevs, useMevsProfit } from 'src/hooks';
import { MevList as MevListResponse } from 'src/types';

import { MevRow } from './MevRow';

export const MevList = (): JSX.Element => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { data, broadcasterId, isFetching } = useMevs(page, rowsPerPage);
  const { profitData, isFetching: isMevsProfitFetching } = useMevsProfit();
  const { bevData, isFetching: isBevProfitFetching } = useBevsProfit();
  const [searchParams] = useSearchParams();
  const mevType = searchParams.get('type');
  const search = searchParams.get('search');
  const arbitrageData = profitData?.find(
    ({ mev_type }) => mev_type === 'arbitrage'
  );
  const sandwichData = profitData?.find(
    ({ mev_type }) => mev_type === 'sandwich'
  );

  useEffect(() => {
    setPage(0);
  }, [broadcasterId, search]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Typography variant="h2" gutterBottom sx={{ flex: '1 0 auto' }}>
        Discovered MEV cases
      </Typography>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={6}>
          <ProfitTable
            title="Potential arbitrage revenue"
            profit={arbitrageData?.profit}
            quantity={arbitrageData?.number_of_mevs}
            tooltip="Maximum potential revenue related to target transactions that appeared after on-chain inclusion. Was extracted by a random searcher or block builder"
            isFetching={isMevsProfitFetching}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {mevType && mevType !== 'arbitrage' ? (
            <ProfitTable
              title="Sandwiches"
              profit={sandwichData?.profit}
              quantity={sandwichData?.number_of_mevs}
              tooltip="Some originators have not enabled front run protection, so users are still facing frontruns. Another reason for this numbers may be network reorgs"
              isFetching={isMevsProfitFetching}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} md={6}>
          <ProfitTable
            title="Actual arbitrage revenue kicked back by Kolibrio"
            profit={bevData?.profit}
            quantity={bevData?.number_of_bevs}
            isFetching={isBevProfitFetching}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack
            sx={{
              width: '100%',
            }}
            direction={{ md: 'row', xs: 'column' }}
            alignItems="center"
            justifyContent="flex-end"
            spacing={{ md: 2, xs: 2 }}>
            <MevSelector
              sx={{
                width: '100%',
                maxWidth: '200px',
              }}
            />
            <Search
              sx={{
                width: '100%',
                maxWidth: '420px',
              }}
            />
          </Stack>
        </Grid>
      </Grid>
      <Filters
        sx={{
          width: '100%',
        }}
      />
      <TableContainer
        className="mev-table-container"
        sx={{
          marginTop: '20px',
          height: '1100px',
        }}>
        <Table
          stickyHeader
          size="small"
          aria-label="collapsible table"
          sx={{ height: search ? null : '450px' }}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} />
              <TableCell align="left" colSpan={3}>
                Bid info
              </TableCell>
              <TableCell align="left">MEV</TableCell>
              <TableCell align="left" width={'150px'} colSpan={3}>
                Broadcaster Transaction
              </TableCell>
              {mevType && mevType !== 'arbitrage' ? (
                <TableCell align="left" width={'150px'} colSpan={3}>
                  Frontrun Transaction
                </TableCell>
              ) : null}
              <TableCell align="left" width={'150px'} colSpan={3}>
                Backrun Transaction
              </TableCell>
              <TableCell colSpan={2} />
            </TableRow>
            <TableRow>
              <TableCell align="left" sx={{ minWidth: '155px' }}>
                Broadcaster ID
              </TableCell>
              <TableCell align="left" sx={{ p: '6px 4px' }}>
                Unique
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  p: '6px 4px',
                }}>
                Bid
              </TableCell>
              <TableCell align="left" sx={{ p: '6px 4px' }}>
                Value
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  p: '6px 4px',
                }}>
                Actual
              </TableCell>
              <TableCell align="center" sx={{ p: '6px 4px', minWidth: '75px' }}>
                Volume $
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  minWidth: '151px',
                }}>
                Hash
              </TableCell>
              <TableCell align="left" sx={{ minWidth: '95px' }}>
                Block
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  minWidth: '68px',
                }}>
                Index
              </TableCell>
              {mevType && mevType !== 'arbitrage' ? (
                <>
                  <TableCell
                    align="left"
                    sx={{
                      minWidth: '151px',
                    }}>
                    Hash
                  </TableCell>
                  <TableCell align="left" sx={{ minWidth: '95px' }}>
                    Block
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      minWidth: '68px',
                    }}>
                    Index
                  </TableCell>
                </>
              ) : null}
              <TableCell
                align="left"
                sx={{
                  minWidth: '151px',
                }}>
                Hash
              </TableCell>
              <TableCell align="left" sx={{ minWidth: '95px' }}>
                Block
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  minWidth: '68px',
                }}>
                Index
              </TableCell>
              <TableCell align="center" sx={{ minWidth: '40px' }}>
                Network
              </TableCell>
              <TableCell align="right" sx={{ minWidth: '165px' }}>
                Age
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <CircularProgress
                color="inherit"
                sx={{ position: 'absolute', left: '50%', mt: '100px' }}
              />
            ) : (
              !!data?.items.length &&
              data.items.map((row: MevListResponse, index: number) => (
                <MevRow key={row.bevId + index} row={row} mevType={mevType} />
              ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell
                sx={{ border: 'none', marginBottom: 0, paddingBottom: 0 }}
                colSpan={10}>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={data?.total || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
