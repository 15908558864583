import { FormControl } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

export const DatePickerCustom = (props: {
  [key: string]: object | string | number;
  paramName: string;
}): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const date = searchParams.get(props.paramName);
  const onChangeHandler = (event: Moment | null): void => {
    if (event) {
      searchParams.set(props.paramName, event.toDate().toDateString());
      searchParams.delete('period');
    } else {
      searchParams.delete(props.paramName);
    }
    setSearchParams(searchParams);
  };

  return (
    <FormControl {...props}>
      <DatePicker
        value={date ? moment(date) : undefined}
        label={props.paramName}
        onChange={onChangeHandler}
        slotProps={{
          field: {
            clearable: true,
            onClear: () => {
              searchParams.delete(props.paramName);
            },
          },
        }}
        maxDate={moment()}
      />
    </FormControl>
  );
};
