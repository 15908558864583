import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Box, Grid, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import {
  BroadcasterSelector,
  BroadcasterStatsTable,
  DatePickerCustom,
  MevList,
  PeriodSelector,
} from 'src/components';
import { BroadcasterRequestsBar } from 'src/components/BroadcasterRequestsBar/BroadcasterRequestsBar';
import { BroadcasterRequestsDoughnut } from 'src/components/BroadcasterRequestsDoughnut/BroadcasterRequestsDoughnut';
import { ProfitFeeTable } from 'src/components/PriorityFeeTable/PriorityFeeTable';
import { ChainIdEnum } from 'src/types';

const UniqueTxTooltip = (): JSX.Element => {
  return (
    <Tooltip title="Unique transactions without duplicates">
      <HelpOutlineOutlinedIcon
        sx={{
          fontSize: '16px',
          verticalAlign: 'middle',
          opacity: '0.5',
        }}
      />
    </Tooltip>
  );
};

export const Stats = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const chainId = searchParams.get('network');

  return (
    <Box>
      <Helmet>
        <title>Kolibrio MEV Dashboard | Broadcaster Stats</title>
      </Helmet>
      <Paper elevation={2} sx={{ p: 2 }}>
        <Box sx={{ mb: 4 }}>
          <Stack
            direction={{ md: 'row', xs: 'column' }}
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 2 }}>
            <Typography variant="h2">
              All transactions, past 30 days <UniqueTxTooltip />
            </Typography>
            <Stack
              direction={{ md: 'row', xs: 'column' }}
              justifyContent="space-between">
              <BroadcasterSelector
                sx={{
                  width: '420px',
                }}
              />
            </Stack>
          </Stack>
          <BroadcasterRequestsBar />
        </Box>
        <Box sx={{ mb: 4 }}>
          <Stack
            direction={{ md: 'row', xs: 'column' }}
            justifyContent="space-between"
            alignItems="center">
            <Typography variant="h2">
              Transactions for the period <UniqueTxTooltip />
            </Typography>
            <Stack
              direction={{ md: 'row', xs: 'column' }}
              justifyContent="space-between">
              <DatePickerCustom
                paramName={'dateFrom'}
                sx={{ marginRight: '20px' }}
              />
              <DatePickerCustom
                paramName={'dateTo'}
                sx={{ marginRight: '60px' }}
              />
              <PeriodSelector sx={{ width: '110px' }} />
            </Stack>
          </Stack>
          <Grid container spacing={2} sx={{ mt: 0 }}>
            <Grid item xs={12} md={4}>
              <BroadcasterRequestsDoughnut />
            </Grid>
            <Grid item xs={12} md={8}>
              <BroadcasterStatsTable />
              {chainId === String(ChainIdEnum.eth) && <ProfitFeeTable />}
            </Grid>
          </Grid>
        </Box>
        <MevList />
        {/* {chainId === '1' && <ValidatorsStatsTable />} */}
      </Paper>
    </Box>
  );
};
