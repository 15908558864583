import './BroadcasterStatsTable.scss';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePrometheus } from 'src/hooks';

import { useUniqueTxs } from '../../hooks/useUniqueTxs';
import { BroadcasterResponseStatus } from '../../types';

type StatsItemType = {
  requests: number;
  part: string;
  timeMs: number | null;
};

type StatsType = {
  [key in BroadcasterResponseStatus]?: StatsItemType;
};

const BroadcasterStatsTableRow = (props: {
  title: string;
  statsItem: StatsItemType;
}): JSX.Element => {
  const { statsItem, title } = props;
  return (
    <TableRow
      className="stats-collapsible-row"
      sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell sx={{ width: '50px', m: 0, p: 0 }} />
      <TableCell component="th" sx={{ pl: 0 }}>
        {title}
      </TableCell>
      <TableCell className={'green'} align="right" sx={{ width: '150px' }}>
        {statsItem.requests.toLocaleString('en-US')}
      </TableCell>
      <TableCell className={'yellow'} align="right" sx={{ width: '150px' }}>
        {statsItem.part}%
      </TableCell>
      <TableCell className={'blue'} align="right" sx={{ width: '150px' }}>
        {statsItem.timeMs ? statsItem.timeMs + 'ms' : ''}
      </TableCell>
    </TableRow>
  );
};

const BroadcasterStatsTableCollapsibleRow = (
  props: PropsWithChildren & {
    title: string;
    statsItem: StatsItemType;
  }
): JSX.Element => {
  const { children, statsItem, title } = props;
  const [open, setOpen] = React.useState(false);
  if (!children) {
    return <BroadcasterStatsTableRow title={title} statsItem={statsItem} />;
  }

  return (
    <>
      <TableRow
        className="stats-collapsible-row"
        sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ width: '50px', m: 0, px: 1, py: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" sx={{ pl: 0 }}>
          {title}
        </TableCell>
        <TableCell className={'green'} align="right" sx={{ width: '150px' }}>
          {statsItem.requests.toLocaleString('en-US')}
        </TableCell>
        <TableCell className={'yellow'} align="right" sx={{ width: '150px' }}>
          {statsItem.part}%
        </TableCell>
        <TableCell className={'blue'} align="right" sx={{ width: '150px' }}>
          {statsItem.timeMs}ms
        </TableCell>
      </TableRow>
      <TableRow className="stats-collapsible-row-child">
        <TableCell sx={{ p: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ m: 0, p: 0 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>{children}</TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export const BroadcasterStatsTable = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const period = (searchParams.get('period') as 'week' | 'month') || 'day';

  const { data, isLoading } = usePrometheus(period);
  const uniqueTxsNumber = useUniqueTxs();
  const requests = data?.requests;
  const latency = data?.latency;

  const totalRequests = requests?.all?.total || 0;
  const content: StatsType = {};

  for (const status in BroadcasterResponseStatus) {
    const requestsState = requests
      ? requests[status as BroadcasterResponseStatus]
      : { total: 0 };
    const latencyState = latency
      ? latency[status as BroadcasterResponseStatus]
      : 0;

    content[status as BroadcasterResponseStatus] = {
      requests: requestsState?.total || 0,
      part:
        requestsState?.total && totalRequests
          ? ((requestsState?.total / totalRequests) * 100).toFixed(2)
          : '0.00',
      timeMs: latencyState || 0,
    };
  }

  const filteredKeys = Object.keys(content).filter(
    (key) =>
      key !== BroadcasterResponseStatus.success &&
      content[key as BroadcasterResponseStatus]?.requests,
    0
  );

  const filteredRequests = filteredKeys.reduce(
    (acc, key) =>
      acc + (content[key as BroadcasterResponseStatus]?.requests || 0),
    0
  );

  const filtered: StatsItemType = {
    requests: filteredRequests,
    part:
      filteredRequests && totalRequests
        ? ((filteredRequests / totalRequests) * 100).toFixed(2)
        : '0.00',
    timeMs: +(
      filteredKeys.reduce((acc, key) => {
        const avgTimeMs =
          content[key as BroadcasterResponseStatus]?.timeMs || 0;
        const reqs = content[key as BroadcasterResponseStatus]?.requests || 0;
        if (avgTimeMs && reqs) {
          return acc + avgTimeMs * reqs;
        }

        return acc;
      }, 0) / filteredRequests
    ).toFixed(),
  };

  const dustTxKeys = Object.keys(content).filter(
    (key) =>
      (key === BroadcasterResponseStatus.ingoredDueToApprovalTx ||
        key === BroadcasterResponseStatus.ingoredDueToEthTransferTx ||
        key === BroadcasterResponseStatus.ingoredDueToTokenTransferTx ||
        key === BroadcasterResponseStatus.ignoredDueToBridgeInteractionTx ||
        key === BroadcasterResponseStatus.ignoredDueToENSTx ||
        key === BroadcasterResponseStatus.ignoredDueToNftTx ||
        key === BroadcasterResponseStatus.ignoredDueToWethTx ||
        key === BroadcasterResponseStatus.ignoredDueToContractCreation) &&
      content[key as BroadcasterResponseStatus]?.requests,
    0
  );

  const dustTxRequests = dustTxKeys.reduce(
    (acc, key) =>
      acc + (content[key as BroadcasterResponseStatus]?.requests || 0),
    0
  );

  const dustTx: StatsItemType = {
    requests: dustTxRequests,
    part:
      dustTxRequests && totalRequests
        ? ((dustTxRequests / totalRequests) * 100).toFixed(2)
        : '0.00',
    timeMs: +(
      dustTxKeys.reduce((acc, key) => {
        const avgTimeMs =
          content[key as BroadcasterResponseStatus]?.timeMs || 0;
        const reqs = content[key as BroadcasterResponseStatus]?.requests || 0;
        if (avgTimeMs && reqs) {
          return acc + avgTimeMs * reqs;
        }

        return acc;
      }, 0) / dustTxRequests
    ).toFixed(),
  };

  const invalidTxKeys = Object.keys(content).filter(
    (key) =>
      key !== BroadcasterResponseStatus.success &&
      !dustTxKeys.includes(key) &&
      content[key as BroadcasterResponseStatus]?.requests,
    0
  );

  const invalidTxRequests = invalidTxKeys.reduce(
    (acc, key) =>
      acc + (content[key as BroadcasterResponseStatus]?.requests || 0),
    0
  );

  const invalidTx: StatsItemType = {
    requests: invalidTxRequests,
    part:
      invalidTxRequests && totalRequests
        ? ((invalidTxRequests / totalRequests) * 100).toFixed(2)
        : '0.00',
    timeMs: +(
      invalidTxKeys.reduce((acc, key) => {
        const avgTimeMs =
          content[key as BroadcasterResponseStatus]?.timeMs || 0;
        const reqs = content[key as BroadcasterResponseStatus]?.requests || 0;
        if (avgTimeMs && reqs) {
          return acc + avgTimeMs * reqs;
        }

        return acc;
      }, 0) / invalidTxRequests
    ).toFixed(),
  };

  const unique: StatsItemType = {
    requests: uniqueTxsNumber,
    part:
      uniqueTxsNumber && totalRequests
        ? ((uniqueTxsNumber / totalRequests) * 100).toFixed(2)
        : '0.00',
    timeMs: null,
  };

  return (
    <TableContainer className={'stats-table'} component={Paper}>
      <Table sx={{ minWidth: '700px' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '50px', m: 0, p: 0 }} />
            <TableCell align="left" sx={{ pl: 0 }}>
              status
            </TableCell>
            <TableCell align="right" sx={{ width: '150px' }}>
              transactions
            </TableCell>
            <TableCell align="right" sx={{ width: '150px' }}>
              % of total
            </TableCell>
            <TableCell align="right" sx={{ width: '150px' }}>
              processing time
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <BroadcasterStatsTableRow
            title={'Auctions Performed'}
            statsItem={
              content[BroadcasterResponseStatus.success] as StatsItemType
            }
          />
          {!!uniqueTxsNumber && (
            <BroadcasterStatsTableRow
              title={'Unique Transactions'}
              statsItem={unique}
            />
          )}
          {!!filtered?.requests && (
            <BroadcasterStatsTableCollapsibleRow
              title={'Filtered'}
              statsItem={filtered}>
              <BroadcasterStatsTableCollapsibleRow
                title={'Dust transactions'}
                statsItem={dustTx}>
                {!!content[BroadcasterResponseStatus.ingoredDueToEthTransferTx]
                  ?.requests && (
                  <BroadcasterStatsTableRow
                    title={'ETH Transfers'}
                    statsItem={
                      content[
                        BroadcasterResponseStatus.ingoredDueToEthTransferTx
                      ] as StatsItemType
                    }
                  />
                )}
                {!!content[
                  BroadcasterResponseStatus.ingoredDueToTokenTransferTx
                ]?.requests && (
                  <BroadcasterStatsTableRow
                    title={'Token Transfers'}
                    statsItem={
                      content[
                        BroadcasterResponseStatus.ingoredDueToTokenTransferTx
                      ] as StatsItemType
                    }
                  />
                )}
                {!!content[BroadcasterResponseStatus.ingoredDueToApprovalTx]
                  ?.requests && (
                  <BroadcasterStatsTableRow
                    title={'Approvals'}
                    statsItem={
                      content[
                        BroadcasterResponseStatus.ingoredDueToApprovalTx
                      ] as StatsItemType
                    }
                  />
                )}
                {!!content[
                  BroadcasterResponseStatus.ignoredDueToBridgeInteractionTx
                ]?.requests && (
                  <BroadcasterStatsTableRow
                    title={'Bridge Interactions'}
                    statsItem={
                      content[
                        BroadcasterResponseStatus
                          .ignoredDueToBridgeInteractionTx
                      ] as StatsItemType
                    }
                  />
                )}
                {!!content[BroadcasterResponseStatus.ignoredDueToENSTx]
                  ?.requests && (
                  <BroadcasterStatsTableRow
                    title={'ENS'}
                    statsItem={
                      content[
                        BroadcasterResponseStatus.ignoredDueToENSTx
                      ] as StatsItemType
                    }
                  />
                )}
                {!!content[BroadcasterResponseStatus.ignoredDueToNftTx]
                  ?.requests && (
                  <BroadcasterStatsTableRow
                    title={'NFTs'}
                    statsItem={
                      content[
                        BroadcasterResponseStatus.ignoredDueToNftTx
                      ] as StatsItemType
                    }
                  />
                )}
                {!!content[BroadcasterResponseStatus.ignoredDueToWethTx]
                  ?.requests && (
                  <BroadcasterStatsTableRow
                    title={'WETH Interactions'}
                    statsItem={
                      content[
                        BroadcasterResponseStatus.ignoredDueToWethTx
                      ] as StatsItemType
                    }
                  />
                )}
                {!!content[
                  BroadcasterResponseStatus.ignoredDueToContractCreation
                ]?.requests && (
                  <BroadcasterStatsTableRow
                    title={'Contracts creation'}
                    statsItem={
                      content[
                        BroadcasterResponseStatus.ignoredDueToContractCreation
                      ] as StatsItemType
                    }
                  />
                )}
              </BroadcasterStatsTableCollapsibleRow>
              <BroadcasterStatsTableCollapsibleRow
                title={'Invalid requests'}
                statsItem={invalidTx}>
                {!!content[BroadcasterResponseStatus.duplicateTransaction]
                  ?.requests && (
                  <BroadcasterStatsTableRow
                    title={'Duplicates'}
                    statsItem={
                      content[
                        BroadcasterResponseStatus.duplicateTransaction
                      ] as StatsItemType
                    }
                  />
                )}

                {!!content[BroadcasterResponseStatus.foundInMempool]
                  ?.requests && (
                  <BroadcasterStatsTableRow
                    title={'Found in Mempool'}
                    statsItem={
                      content[
                        BroadcasterResponseStatus.foundInMempool
                      ] as StatsItemType
                    }
                  />
                )}
                {!!content[BroadcasterResponseStatus.maxFeePerGasIsTooLow]
                  ?.requests && (
                  <BroadcasterStatsTableRow
                    title={'Gas Price Too Low'}
                    statsItem={
                      content[
                        BroadcasterResponseStatus.maxFeePerGasIsTooLow
                      ] as StatsItemType
                    }
                  />
                )}
                {!!content[BroadcasterResponseStatus.blacklistedBroadcaster]
                  ?.requests && (
                  <BroadcasterStatsTableRow
                    title={'Blacklisted Broadcaster'}
                    statsItem={
                      content[
                        BroadcasterResponseStatus.blacklistedBroadcaster
                      ] as StatsItemType
                    }
                  />
                )}
                {!!content[
                  BroadcasterResponseStatus.ignoredDueToFailedSimulation
                ]?.requests && (
                  <BroadcasterStatsTableRow
                    title={'Reverted During Simulation or Empty Logs'}
                    statsItem={
                      content[
                        BroadcasterResponseStatus.ignoredDueToFailedSimulation
                      ] as StatsItemType
                    }
                  />
                )}
                {!!content[BroadcasterResponseStatus.invalidGasLimit]
                  ?.requests && (
                  <BroadcasterStatsTableRow
                    title={'Invalid Gas Limit'}
                    statsItem={
                      content[
                        BroadcasterResponseStatus.invalidGasLimit
                      ] as StatsItemType
                    }
                  />
                )}
              </BroadcasterStatsTableCollapsibleRow>
            </BroadcasterStatsTableCollapsibleRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
