import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { networks } from 'src/config';

export const NetworkSelector = (props: {
  [key: string]: object | string | number;
}): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const chainId = searchParams.get('network');

  const onChangeHandler = (event: SelectChangeEvent<string>): void => {
    event.target.value
      ? searchParams.set('network', event.target.value)
      : searchParams.delete('network');
    setSearchParams(searchParams);
  };

  return (
    <FormControl {...props}>
      <InputLabel id="network-select-label">Network</InputLabel>
      <Select
        sx={{ height: '40px' }}
        labelId="network-select-label"
        id="network-select"
        value={chainId || '0'}
        label="network"
        onChange={onChangeHandler}>
        {networks.map((network) => (
          <MenuItem key={network.chainId} value={network.chainId}>
            <Grid container alignItems="center">
              <img
                style={{ width: '20px', height: '20px' }}
                src={network.icon}></img>
              <Grid item sx={{ pl: '10px' }}>
                {network.name}
              </Grid>
            </Grid>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
