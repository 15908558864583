import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Box, Checkbox, FormControlLabel, Grid, Tooltip } from '@mui/material';
import { ChangeEvent } from 'react';
import { useSearchParams } from 'react-router-dom';

export const Filters = (props: {
  [key: string]: object | string | number;
}): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const unique = searchParams.get('unique') === 'true' ? true : false;
  const notSameBlockBackrun =
    searchParams.get('notSameBlockBackrun') === 'true' ? true : false;
  const onSwitchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = event.target;
    searchParams.set(name, String(checked));
    setSearchParams(searchParams);
  };
  const mevType = searchParams.get('type');

  return (
    <Grid sx={{ mt: '20px' }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={unique}
            onChange={onSwitchChange}
            name={'unique'}
          />
        }
        label="Unique"
      />
      <Box
        sx={{
          display: 'inline',
          position: 'relative',
          right: '12px',
          top: '1px',
        }}>
        <Tooltip title="Was only on Kolibrio Private Stream. Not appeared at Mev Blocker, Mev Share, Mempool, etc.">
          <HelpOutlineOutlinedIcon
            sx={{ fontSize: '16px', verticalAlign: 'middle' }}
          />
        </Tooltip>
      </Box>
      {mevType !== 'sandwich' && (
        <FormControlLabel
          control={
            <Checkbox
              checked={notSameBlockBackrun}
              onChange={onSwitchChange}
              name={'notSameBlockBackrun'}
            />
          }
          label="Not Same Block Backrun"
        />
      )}
    </Grid>
  );
};
