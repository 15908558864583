import './ProfitTable.scss';

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export const ProfitTable = ({
  title,
  profit,
  quantity,
  tooltip,
  isFetching,
}: {
  title: string;
  profit?: number;
  quantity?: string;
  tooltip?: string;
  isFetching?: boolean;
}): JSX.Element => (
  <TableContainer className={'profit-table'} component={Paper}>
    <Table size="small">
      <TableBody>
        <TableRow
          className="profit-collapsible-row"
          sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell component="th" sx={{ pl: '16px' }}>
            <Grid container alignItems="center">
              {title}
              {tooltip && (
                <Tooltip title={tooltip}>
                  <HelpOutlineOutlinedIcon
                    sx={{
                      fontSize: '14px',
                      verticalAlign: 'middle',
                      ml: '3px',
                    }}
                  />
                </Tooltip>
              )}
            </Grid>
          </TableCell>
          <TableCell className={'green'} align="right">
            {isFetching ? (
              <CircularProgress color="inherit" size={16} />
            ) : (
              `${profit?.toFixed(2) || 0} $`
            )}
          </TableCell>
          <TableCell className={'yellow'} align="right" sx={{ width: '80px' }}>
            {isFetching ? null : quantity || 0}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);
