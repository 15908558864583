import { Box, Container, CssBaseline } from '@mui/material';

import Footer from './components/Footer/Footer';
import NavBar from './components/NavBar/NavBar';

export const Layout = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}>
      <CssBaseline />
      <NavBar />
      <Container
        component="main"
        sx={{ mt: 12, mb: 0, maxWidth: '1300px' }}
        maxWidth="xl">
        {children}
      </Container>
      <Footer
        sx={{
          py: 2,
          px: 2,
          mt: 'auto',
        }}
      />
    </Box>
  );
};
