import './PriorityFeeTable.scss';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { useNoMevTxsGasDifference } from 'src/hooks';

import { BroadcasterResponseStatus } from '../../types';

type NoMevItemType = {
  gasDifferenceSum: number;
  numberOfTxs: number;
  type: string;
};

type NoMevType = {
  [key in BroadcasterResponseStatus]?: NoMevItemType;
};

const PriorityFeeTableRow = (props: {
  title: string;
  statsItem: NoMevItemType;
}): JSX.Element => {
  const { statsItem, title } = props;
  return (
    <TableRow
      className="priority-fee-collapsible-row"
      sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell sx={{ width: '50px', m: 0, p: 0 }} />
      <TableCell component="th" sx={{ pl: 0 }}>
        {title}
      </TableCell>
      <TableCell className={'green'} align="right" sx={{ width: '150px' }}>
        {Number(statsItem.numberOfTxs).toFixed()}
      </TableCell>
      <TableCell className={'yellow'} align="right" sx={{ width: '150px' }}>
        {statsItem.gasDifferenceSum.toFixed(2)} $
      </TableCell>
      <TableCell className={'yellow'} align="right" sx={{ width: '150px' }} />
    </TableRow>
  );
};

const PriorityFeeTableCollapsibleRow = (
  props: PropsWithChildren & {
    title: string;
    statsItem: NoMevItemType;
  }
): JSX.Element => {
  const { children, statsItem, title } = props;
  const [open, setOpen] = React.useState(false);
  if (!children) {
    return <PriorityFeeTableRow title={title} statsItem={statsItem} />;
  }

  return (
    <>
      <TableRow
        className="priority-fee-collapsible-row"
        sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ width: '50px', m: 0, px: 1, py: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" sx={{ pl: 0 }}>
          {title}
        </TableCell>
        <TableCell className={'green'} align="right" sx={{ width: '150px' }}>
          {statsItem.numberOfTxs}
        </TableCell>
        <TableCell className={'yellow'} align="right" sx={{ width: '150px' }}>
          {statsItem.gasDifferenceSum.toFixed(2)} $
        </TableCell>
        <TableCell className={'yellow'} align="right" sx={{ width: '150px' }} />
      </TableRow>
      <TableRow className="priority-fee-collapsible-row-child">
        <TableCell sx={{ p: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ m: 0, p: 0 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>{children}</TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export const ProfitFeeTable = (): JSX.Element => {
  const { data, isLoading } = useNoMevTxsGasDifference();

  if (!data || !Object.keys(data).length) {
    return <></>;
  }

  const total: NoMevItemType = {
    gasDifferenceSum: 0,
    numberOfTxs: 0,
    type: 'total',
  };

  const content: NoMevType = {};

  for (const key in data) {
    total.gasDifferenceSum += data[key].gasDifferenceSum;
    total.numberOfTxs += Number(data[key].numberOfTxs);
    content[key as BroadcasterResponseStatus] = {
      gasDifferenceSum: data[key].gasDifferenceSum,
      numberOfTxs: Number(data[key].numberOfTxs),
      type: data[key].type,
    };
  }

  return (
    <TableContainer className={'priority-fee-table'} component={Paper}>
      <Table sx={{ minWidth: '700px' }}>
        <TableBody>
          <PriorityFeeTableCollapsibleRow
            title={'Priority Fee Value'}
            statsItem={total}>
            {!!content[BroadcasterResponseStatus.ingoredDueToEthTransferTx] && (
              <PriorityFeeTableRow
                title={'ETH Transfers'}
                statsItem={
                  content[
                    BroadcasterResponseStatus.ingoredDueToEthTransferTx
                  ] as NoMevItemType
                }
              />
            )}
            {!!content[
              BroadcasterResponseStatus.ingoredDueToTokenTransferTx
            ] && (
              <PriorityFeeTableRow
                title={'Token Transfers'}
                statsItem={
                  content[
                    BroadcasterResponseStatus.ingoredDueToTokenTransferTx
                  ] as NoMevItemType
                }
              />
            )}
            {!!content[BroadcasterResponseStatus.ingoredDueToApprovalTx] && (
              <PriorityFeeTableRow
                title={'Approvals'}
                statsItem={
                  content[
                    BroadcasterResponseStatus.ingoredDueToApprovalTx
                  ] as NoMevItemType
                }
              />
            )}
            {!!content[
              BroadcasterResponseStatus.ignoredDueToBridgeInteractionTx
            ] && (
              <PriorityFeeTableRow
                title={'Bridge Interactions'}
                statsItem={
                  content[
                    BroadcasterResponseStatus.ignoredDueToBridgeInteractionTx
                  ] as NoMevItemType
                }
              />
            )}
            {!!content[BroadcasterResponseStatus.ignoredDueToENSTx] && (
              <PriorityFeeTableRow
                title={'ENS'}
                statsItem={
                  content[
                    BroadcasterResponseStatus.ignoredDueToENSTx
                  ] as NoMevItemType
                }
              />
            )}
            {!!content[BroadcasterResponseStatus.ignoredDueToNftTx] && (
              <PriorityFeeTableRow
                title={'NFTs'}
                statsItem={
                  content[
                    BroadcasterResponseStatus.ignoredDueToNftTx
                  ] as NoMevItemType
                }
              />
            )}
            {!!content[BroadcasterResponseStatus.ignoredDueToWethTx] && (
              <PriorityFeeTableRow
                title={'WETH Interactions'}
                statsItem={
                  content[
                    BroadcasterResponseStatus.ignoredDueToWethTx
                  ] as NoMevItemType
                }
              />
            )}
            {!!content[
              BroadcasterResponseStatus.ignoredDueToContractCreation
            ] && (
              <PriorityFeeTableRow
                title={'Contract creation'}
                statsItem={
                  content[
                    BroadcasterResponseStatus.ignoredDueToContractCreation
                  ] as NoMevItemType
                }
              />
            )}
          </PriorityFeeTableCollapsibleRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
