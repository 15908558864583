import { Backdrop, Box, CircularProgress } from '@mui/material';
import {
  BubbleDataPoint,
  Chart as ChartJS,
  ChartData,
  ChartDataset,
  Point,
  registerables,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { useSearchParams } from 'react-router-dom';
import { networks } from 'src/config';
import { useDailyRequests } from 'src/hooks';
import { ChainIdEnum } from 'src/types';

ChartJS.register(...registerables);
ChartJS.register(ChartDataLabels);

export const BroadcasterRequestsBar = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const chainId = searchParams.get('network');

  const startDate = new Date();
  startDate.setMilliseconds(0);
  startDate.setSeconds(0);
  startDate.setMinutes(0);
  startDate.setHours(0);

  const endTimestamp = startDate.getTime();
  startDate.setDate(startDate.getDate() - 30);

  const startTimestamp = startDate.getTime();
  const stats = useDailyRequests(startTimestamp, endTimestamp);

  const datasets: ChartDataset<
    'bar',
    (number | [number, number] | Point | BubbleDataPoint | null)[]
  >[] = networks
    .filter(
      (network) =>
        network.chainId > 0 && (!chainId || network.chainId === +chainId)
    )
    .map((network) => {
      return {
        label: network.name,
        data: [],
        backgroundColor: network.color || '#ddd',
        datalabels: {
          display: false,
        },
      };
    });

  const data: ChartData<
    'bar',
    (number | [number, number] | Point | BubbleDataPoint | null)[],
    unknown
  > = {
    labels: [],
    datasets,
  };

  if (stats.data) {
    stats.data.forEach((row) => {
      const chains = row[1].chains;

      datasets.forEach((dataset) => {
        const network = networks.find((n) => n.name === dataset.label);

        const chainValue = chains[network?.chainId as ChainIdEnum];
        if (!chainValue) {
          dataset.data.push(0);

          return;
        }

        dataset.data.push(chainValue);
      });

      data.labels?.push(String(moment(row[0] * 1000 - 1).format('MMM DD')));
    });
  }

  return (
    <Box>
      <Box sx={{ position: 'relative', height: '250px' }}>
        <Backdrop
          open={stats.isLoading}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            color: '#ddd',
            background: 'none',
          }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {!!stats && !!data && !!data.labels && data.labels.length > 0 && (
          <Bar
            data={data}
            options={{
              maintainAspectRatio: false,
              interaction: {
                intersect: false,
                mode: 'index',
              },
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  display: false,
                  // align: 'end',
                  // anchor: 'end',
                  // rotation: -90,
                  // formatter: (value, context) => {
                  //   if (context.datasetIndex === data.datasets.length - 1) {
                  //     const sum = context.chart.data.datasets.reduce(
                  //       (acc, dataset) => {
                  //         const val = Number(
                  //           dataset.data[context.dataIndex] || 0
                  //         );

                  //         return Number(acc) + val;
                  //       },
                  //       0
                  //     );
                  //     return sum;
                  //   }
                  //   return null;
                  // },
                },
                tooltip: {
                  callbacks: {
                    footer: (tooltipItems) => {
                      let sum = 0;

                      tooltipItems.forEach(function (tooltipItem) {
                        sum += tooltipItem.parsed.y;
                      });

                      return 'Total: ' + sum;
                    },
                  },
                },
              },
              responsive: true,
              scales: {
                x: {
                  display: true,
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
};
