import { Autocomplete, TextField } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useBroadcastersIds } from 'src/hooks';

export const BroadcasterSelector = (props: {
  [key: string]: object | string | number;
}): JSX.Element => {
  const broadcastersIds = useBroadcastersIds() || [];
  const [searchParams, setSearchParams] = useSearchParams();
  const broadcasterId = searchParams.get('broadcasterId');

  const onChangeHandler = (event: object, value: string | null): void => {
    value
      ? searchParams.set('broadcasterId', value)
      : searchParams.delete('broadcasterId');
    setSearchParams(searchParams);
  };

  return (
    <Autocomplete
      {...props}
      size="small"
      disablePortal
      value={broadcasterId || null}
      options={broadcastersIds || []}
      onChange={onChangeHandler}
      renderInput={(params) => <TextField {...params} label="BroadcasterID" />}
    />
  );
};
