import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useSearchersCount = (): number => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [searchParams] = useSearchParams();
  const chainId = searchParams.get('network');

  const { data, refetch } = useQuery(
    ['searchers'],
    async () => {
      const { data } = await axios({
        url: `${apiUrl}/stats/connectedSearchers`,
        params: {
          chainId: Number(chainId) || undefined,
        },
      });
      return data;
    },
    { enabled: false }
  );

  useEffect(() => {
    refetch();
    const timer = setInterval(refetch, 5000);
    return () => {
      clearInterval(timer);
    };
  }, [chainId, refetch]);

  return data;
};
