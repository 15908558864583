import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useUniqueTxs = (): number => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [searchParams] = useSearchParams();
  const chainId = searchParams.get('network');
  const broadcasterId = searchParams.get('broadcasterId');
  const period =
    (searchParams.get('period') as 'week' | 'month' | 'day') || 'day';
  const dateFromString = searchParams.get('dateFrom');
  const dateToString = searchParams.get('dateTo');
  const dateFrom = dateFromString ? new Date(dateFromString) : undefined;
  const dateTo = dateToString ? new Date(dateToString) : undefined;
  const { data, refetch } = useQuery(
    ['unique-txs', period],
    async () => {
      const { data } = await axios({
        url: `${apiUrl}/stats/unique-txs`,
        params: {
          period,
          chainId: Number(chainId) || undefined,
          broadcasterId:
            broadcasterId === 'Unknown Broadcaster' ? 'unknown' : broadcasterId,
          dateFrom,
          dateTo,
        },
      });
      return data;
    },
    { enabled: false }
  );
  useEffect(() => {
    refetch();
  }, [broadcasterId, chainId, period, dateFrom, dateTo, refetch]);

  return data;
};
