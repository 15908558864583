import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useBroadcastersIds = (): string[] => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [searchParams] = useSearchParams();
  const chainId = searchParams.get('network');

  const { data, refetch } = useQuery(
    ['broadcasters'],
    async () => {
      const { data } = await axios({
        url: `${apiUrl}/bundle/broadcasters`,
        params: {
          chainId: Number(chainId) || undefined,
        },
      });

      return data;
    },
    { enabled: false }
  );

  useEffect(() => {
    refetch();
  }, [chainId, refetch]);

  return data;
};
