import { Box, Container, Link, Typography } from '@mui/material';

const Footer = (props: {
  [key: string]: object | string | number;
}): JSX.Element => {
  const Copyright = (): JSX.Element => {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://kolibr.io/">
          kolibr.io
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  };

  return (
    <Box component="footer" {...props}>
      <Container maxWidth="sm">
        <Copyright />
      </Container>
    </Box>
  );
};

export default Footer;
