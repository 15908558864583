import { Box, Grid, Paper } from '@mui/material';
import {
  BroadcasterRequestsCount,
  BundleList,
  SearcherCount,
} from 'src/components';

export const Dashboard = (): JSX.Element => {
  return (
    <Box>
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            elevation={2}
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 160,
            }}>
            <BroadcasterRequestsCount />
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={4} lg={3}>
          <Paper
            elevation={2}
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 160,
            }}>
            <SearcherCount />
          </Paper>
        </Grid>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper
            elevation={2}
            sx={{
              p: 2,
            }}>
            <BundleList />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
