import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';

export const PeriodSelector = (props: {
  [key: string]: object | string | number;
}): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const period = searchParams.get('period');

  const onChangeHandler = (event: SelectChangeEvent<string>): void => {
    event.target.value !== 'day'
      ? searchParams.set('period', event.target.value)
      : searchParams.delete('period');
    searchParams.delete('dateFrom');
    searchParams.delete('dateTo');
    setSearchParams(searchParams);
  };

  return (
    <FormControl {...props}>
      <InputLabel id="period-select-label">Period</InputLabel>
      <Select
        sx={{ height: '40px' }}
        labelId="period-select-label"
        id="period-select"
        value={period || 'day'}
        label="period"
        onChange={onChangeHandler}>
        <MenuItem value={'day'}>24 hours</MenuItem>
        <MenuItem value={'week'}>7 days</MenuItem>
        <MenuItem value={'month'}>30 days</MenuItem>
      </Select>
    </FormControl>
  );
};
