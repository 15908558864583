import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RequestsStats } from 'src/types';

export const usePrometheus = (
  period?: 'week' | 'month' | 'day'
): {
  data: RequestsStats;
  isLoading: boolean;
} => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [searchParams] = useSearchParams();

  if (!period) {
    period = (searchParams.get('period') as 'week' | 'month' | 'day') || 'day';
  }
  const dateFromString = searchParams.get('dateFrom');
  const dateToString = searchParams.get('dateTo');
  const dateFrom = dateFromString ? new Date(dateFromString) : undefined;
  const dateTo = dateToString ? new Date(dateToString) : undefined;
  const chainId = searchParams.get('network');
  const broadcasterId = searchParams.get('broadcasterId');

  const { data, isLoading, refetch } = useQuery(
    ['requests', period],
    async () => {
      const { data } = await axios({
        url: `${apiUrl}/stats/requests`,
        params: {
          broadcasterId:
            broadcasterId === 'Unknown Broadcaster' ? 'unknown' : broadcasterId,
          chainId,
          period,
          dateFrom,
          dateTo,
        },
      });

      return data;
    },
    { enabled: false }
  );

  useEffect(() => {
    refetch();
    const timer = setInterval(refetch, 15000);
    return () => {
      clearInterval(timer);
    };
  }, [broadcasterId, chainId, period, dateFrom, dateTo, refetch]);

  return { data, isLoading };
};
