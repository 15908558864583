import './NavBar.scss';

import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ELink from '@mui/material/Link';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { NetworkSelector } from 'src/components';
import { pages } from 'src/config';

const NavBar = (): JSX.Element => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const isMobile = useMediaQuery('(max-width: 599px)');

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (): void => {
    setAnchorElNav(null);
  };

  const LogoBox = (): JSX.Element => {
    return (
      <Box className="logo-box">
        <Link to="/">
          <Box
            component="img"
            sx={{
              maxHeight: '35px',
            }}
            alt="Kolibrio"
            src="/logo.png"
          />
          <Box component={'span'}>explorer</Box>
        </Link>
      </Box>
    );
  };

  const NavButton = ({
    children,
  }: {
    children: React.ReactNode;
  }): JSX.Element => {
    return (
      <Button
        onClick={handleCloseNavMenu}
        sx={{ my: 2, color: 'black', display: 'block' }}>
        {children}
      </Button>
    );
  };

  return (
    <AppBar
      className="nav-bar"
      position="fixed"
      sx={{
        backgroundColor: 'white',
      }}>
      <Container sx={{ maxWidth: '1300px' }} maxWidth="xl">
        <Toolbar disableGutters>
          {isMobile ? (
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color={'primary'}>
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}>
                {pages.map((page) => (
                  <MenuItem
                    key={page.name.toLowerCase()}
                    onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">
                      {!['http', 'https'].includes(page.link.split(':')[0]) ? (
                        <Link
                          className="nav-link"
                          key={page.name.toLowerCase()}
                          to={page.link}>
                          {page.name}
                        </Link>
                      ) : (
                        <ELink
                          className="nav-link"
                          key={page.name.toLowerCase()}
                          href={page.link}
                          target="_blank"
                          rel="noreferrer">
                          {page.name}
                        </ELink>
                      )}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ) : (
            <>
              <LogoBox />
              <Box sx={{ flexGrow: 1, display: 'flex' }}>
                {pages.map((page) =>
                  !['http', 'https'].includes(page.link.split(':')[0]) ? (
                    <Link
                      className="nav-link"
                      key={page.name.toLowerCase()}
                      to={page.link}>
                      <NavButton>{page.name}</NavButton>
                    </Link>
                  ) : (
                    <ELink
                      className="nav-link"
                      key={page.name.toLowerCase()}
                      href={page.link}
                      target="_blank"
                      rel="noreferrer">
                      <NavButton>{page.name}</NavButton>
                    </ELink>
                  )
                )}
              </Box>
            </>
          )}
          <NetworkSelector sx={{ width: '200px' }} />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
