import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RequestsStatsItem } from 'src/types';

export const useDailyRequests = (
  startTimestamp: number,
  endTimestamp: number
): {
  data: [number, RequestsStatsItem][];
  isLoading: boolean;
} => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [searchParams] = useSearchParams();

  const chainId = searchParams.get('network');
  const broadcasterId = searchParams.get('broadcasterId');

  const { data, isLoading, refetch } = useQuery(
    ['requests', startTimestamp, endTimestamp, endTimestamp],
    async () => {
      const { data } = await axios({
        url: `${apiUrl}/stats/requests/daily`,
        params: {
          startTimestamp,
          endTimestamp,
          broadcasterId:
            broadcasterId === 'Unknown Broadcaster' ? 'unknown' : broadcasterId,
          chainId,
        },
      });

      return data;
    },
    { enabled: false }
  );

  useEffect(() => {
    refetch();
    const timer = setInterval(refetch, 60000);
    return () => {
      clearInterval(timer);
    };
  }, [broadcasterId, chainId, startTimestamp, refetch]);

  return { data, isLoading };
};
