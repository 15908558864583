import { CssBaseline } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { Layout, ThemeProvider } from 'src/components';
import { Dashboard, NotFound, SearcherStats, Stats } from 'src/pages';

const App = (): JSX.Element => {
  return (
    <ThemeProvider>
      <CssBaseline />
      <Layout>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/searchersStats" element={<SearcherStats />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </ThemeProvider>
  );
};

export default App;
