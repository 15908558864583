import { Page } from '../types';

export const pages: Page[] = [
  {
    name: 'Home',
    link: '/',
  },
  {
    name: 'Broadcasters Stats',
    link: '/stats',
  },
  {
    name: 'Docs',
    link: 'https://docs.kolibr.io',
  },
];
