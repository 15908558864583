import './SearchersStatsTable.scss';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { hiddenSearchers } from 'src/config';
import { useSearchersStats } from 'src/hooks';
import { SearchersStatsRowInterface } from 'src/types';

import { SearchersStatsRow } from './SearcherStatsRow';

export const SearchersStatsTable = (): JSX.Element => {
  const data = useSearchersStats();
  return (
    <>
      <Typography variant="h2" gutterBottom>
        Searchers Stats
      </Typography>
      <TableContainer>
        <Table
          className="searchers-stats"
          aria-label="simple table"
          padding="none">
          <TableHead>
            <TableRow>
              <TableCell align="left">Address</TableCell>
              <TableCell align="center">Network</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((searcherStat: SearchersStatsRowInterface) => {
                if (!hiddenSearchers.includes(searcherStat.primaryAddress)) {
                  return (
                    <SearchersStatsRow
                      key={searcherStat.primaryAddress + searcherStat.chainId}
                      row={searcherStat}
                    />
                  );
                }
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
